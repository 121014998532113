export const PETER_PANS_FLIGHT = '86a41273-5f15-4b54-93b6-829f140e5161';
export const JUNGLE_CRUISE = '796b0a25-c51e-456e-9bb8-50a324e301b3';
export const SPACE_MOUNTAIN = 'b2260923-9315-40fd-9c6b-44dd811dbe64';
export const TIANAS_BAYOU_ADVENTURE = '73cb9445-0695-47a3-87ce-d08ae36b5f3c';
export const BARNSTORMER = '924a3b2c-6b4b-49e5-99d3-e9dc3f2e8a48';
export const BUZZ_LIGHTYEAR = '72c7343a-f7fb-4f66-95df-c91016de7338';
export const DUMBO = '890fa430-89c0-4a3f-96c9-11597888005e';
export const HAUNTED_MANSION = '2551a77d-023f-4ab1-9a19-8afec0190f39';
export const ITS_A_SMALL_WORLD = 'f5aad2d4-a419-4384-bd9a-42f86385c750';
export const MAD_TEA_PARTY = '0aae716c-af13-4439-b638-d75fb1649df3';
export const MAGIC_CARPETS_OF_ALADDIN = '96455de6-f4f1-403c-9391-bf8396979149';
export const WINNIE_THE_POOH = '0d94ad60-72f0-4551-83a6-ebaecdd89737';
export const PHILHARMAGIC = '7c5e1e02-3a44-4151-9005-44066d5ba1da';
export const MONSTERS_INC_LAUGH_FLOOR = 'e8f0b426-7645-4ea3-8b41-b94ae7091a41';
export const PIRATES_OF_THE_CARIBBEAN = '352feb94-e52e-45eb-9c92-e4b44c6b1a9d';
export const TOMORROWLAND_SPEEDWAY = 'f163ddcd-43e1-488d-8276-2381c1db0a39';
export const UNDER_THE_SEA_LITTLE_MERMAID = '3cba0cb4-e2a6-402c-93ee-c11ffcb127ef';
export const BIG_THUNDER_MOUNTAIN = 'de3309ca-97d5-4211-bffe-739fed47e92f';
export const TRON = '5a43d1a7-ad53-4d25-abfe-25625f0da304';
export const ENCHANTED_TALES_WITH_BELLE = 'e76c93df-31af-49a5-8e2f-752c76c937c9';