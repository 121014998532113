import { DINOSAUR, EXPEDITION_EVEREST, FEATHERED_FRIENDS_IN_FLIGHT, FESTIVAL_OF_THE_LION_KING, FINDING_NEMO_BIG_BLUE_BEYOND, ITS_TOUGH_TO_BE_A_BUG, KALI_RIVER_RAPIDS, KILIMANJAROS_SAFARI, NAVI_RIVER_JOURNEY } from "../constants/attractions/animalkingdom";
import { FROZEN, IMAGINATION_WITH_FIGMENT, LIVING_WITH_THE_LAND, MISSION_SPACE, NEMO_AND_FRIENDS, PIXAR_SHORT_FILM_FESTIVAL, REMYS, SOARIN, SPACESHIP_EARTH, TEST_TRACK, TURTLE_TALK_WITH_CRUSH } from "../constants/attractions/epcot";
import { ALIEN_SWIRLING_SAUCERS, BEAUTY_AND_BEAST_SHOW, DISNEY_JUNIOR_PLAY_AND_DANCE, FROZEN_SING_A_LONG, INDIANA_JONES_STUNT_SPECTACULAR, MUPPET_VISION, ROCK_N_ROLLER_COASTER, RUNAWAY_RAILWAY, SLINKY_DOG_DASH, SMUGGLERS_RUN, STAR_TOURS, TOWER_OF_TERROR, TOY_STORY_MANIA } from "../constants/attractions/hollywoodstudios";
import { BARNSTORMER, BIG_THUNDER_MOUNTAIN, BUZZ_LIGHTYEAR, DUMBO, HAUNTED_MANSION, ITS_A_SMALL_WORLD, JUNGLE_CRUISE, MAD_TEA_PARTY, MAGIC_CARPETS_OF_ALADDIN, MONSTERS_INC_LAUGH_FLOOR, PETER_PANS_FLIGHT, PHILHARMAGIC, PIRATES_OF_THE_CARIBBEAN, SPACE_MOUNTAIN, TIANAS_BAYOU_ADVENTURE, TOMORROWLAND_SPEEDWAY, UNDER_THE_SEA_LITTLE_MERMAID, WINNIE_THE_POOH } from "../constants/attractions/magickingdom";
import { ANIMAL_KINGDOM, DISNEYLAND, DISNEY_CALIFORNIA_ADVENTURE, EPCOT, HOLLYWOOD_STUDIOS, MAGIC_KINGDOM } from "../constants/parks";

export const tiers: {[parkId: string] : {[attractionId: string] : number}} = {
    [MAGIC_KINGDOM]: { // MK
        [PETER_PANS_FLIGHT]: 1,
        [JUNGLE_CRUISE]: 1,        
        [SPACE_MOUNTAIN]: 1,
        [TIANAS_BAYOU_ADVENTURE]: 1,
        [BIG_THUNDER_MOUNTAIN]: 1,

        [BARNSTORMER]: 2,
        [BUZZ_LIGHTYEAR]: 2,
        [DUMBO]: 2,
        [HAUNTED_MANSION]: 2,
        [ITS_A_SMALL_WORLD]: 2,
        [MAD_TEA_PARTY]: 2,
        [MAGIC_CARPETS_OF_ALADDIN]: 2,
        [WINNIE_THE_POOH]: 2,
        [PHILHARMAGIC]: 2,
        [MONSTERS_INC_LAUGH_FLOOR]: 2,
        [PIRATES_OF_THE_CARIBBEAN]: 2,
        [TOMORROWLAND_SPEEDWAY]: 2,
        [UNDER_THE_SEA_LITTLE_MERMAID]: 2,
    },
    [EPCOT]: {  // Epcot
        [FROZEN]: 1,
        [REMYS]: 1,
        [SOARIN]: 1,
        [TEST_TRACK]: 1,

        [PIXAR_SHORT_FILM_FESTIVAL]: 2,
        [IMAGINATION_WITH_FIGMENT]: 2,
        [LIVING_WITH_THE_LAND]: 2,
        [MISSION_SPACE]: 2,
        [NEMO_AND_FRIENDS]: 2,
        [SPACESHIP_EARTH]: 2,
        [TURTLE_TALK_WITH_CRUSH]: 2,
    },
    [HOLLYWOOD_STUDIOS]: {
        [RUNAWAY_RAILWAY]: 1,
        [SMUGGLERS_RUN]: 1,
        [ROCK_N_ROLLER_COASTER]: 1,
        [SLINKY_DOG_DASH]: 1,

        [ALIEN_SWIRLING_SAUCERS]: 2,
        [BEAUTY_AND_BEAST_SHOW]: 2,
        [DISNEY_JUNIOR_PLAY_AND_DANCE]: 2,
        [FROZEN_SING_A_LONG]: 2,
        [INDIANA_JONES_STUNT_SPECTACULAR]: 2,
        [MUPPET_VISION]: 2,
        [STAR_TOURS]: 2,
        [TOWER_OF_TERROR]: 2,
        [TOY_STORY_MANIA]: 2,
    },
    [ANIMAL_KINGDOM]: {
        [DINOSAUR]: 1,
        [EXPEDITION_EVEREST]: 1,
        [FEATHERED_FRIENDS_IN_FLIGHT]: 1,
        [FESTIVAL_OF_THE_LION_KING]: 1,
        [FINDING_NEMO_BIG_BLUE_BEYOND]: 1,
        [ITS_TOUGH_TO_BE_A_BUG]: 1,
        [KALI_RIVER_RAPIDS]: 1,
        [KILIMANJAROS_SAFARI]: 1,
        [NAVI_RIVER_JOURNEY]: 1,
    },
    [DISNEYLAND]: { // DL
        '2aedc657-1ee2-4545-a1ce-14753f28cc66': 1, // indiana jones
        'faaa8be9-cc1e-4535-ac20-04a535654bd0': 1, // matterhorn
        'cd670bff-81d1-4f34-8676-7bafdf49220a': 1, // runaway railway
        '9167db1d-e5e7-46da-a07f-ae30a87bc4c4': 1, // space mountain

        '0de1413a-73ee-46cf-af2e-c491cc7c7d3b': 2, // big thunder
        '88197808-3c56-4198-a5a4-6066541251cf': 2, // buzz lightyear astro blasters
        'ff52cb64-c1d5-4feb-9d43-5dbd429bac81': 2, // haunted mansion
        'b2c2549c-e9da-4fdd-98ea-1dcff596fed7': 2, // smugglers run
        '82aeb29b-504a-416f-b13f-f41fa5b766aa': 2, // pirates
        '6ce9cdd1-0a43-459e-83cd-f4cace9cfa7b': 2, // roger rabbit
        'cc718d11-fa15-44ee-87d0-ded989ad61bc': 2, // star tours

        '3638ac09-9fce-4a43-8c79-8ebbe17afce2': 3, // small world
        '1da85181-bf0f-4ccc-b98e-243142f7347b': 3, // autopia
    },
    [DISNEY_CALIFORNIA_ADVENTURE]: { // DCA
        'b7678dab-5544-48d5-8fdc-c1a0127cfbcd': 1, // mission breakout
        '86ab3069-110d-49c5-a7e7-29ddf28695a6': 1, // midway mania
        '2295351d-ce6b-4c04-92d5-5b416372c5b5': 1, // webslingers

        'f44a5072-3cda-4c7c-8574-33ad09d16cca': 2, // goofy's sky school
        'b1d285a7-2444-4a7c-b7bb-d2d4d6428a85': 2, // grizzly river run
        '5d07a2b1-49ca-4de7-9d32-6d08edf69b08': 2, // incredicoaster
        '40524fba-5d84-49e7-9204-f493dbe2d5a4': 2, // monsters inc
        '77f205a4-d482-4d91-a5ff-71e54a086ad2': 2, // soarin

        'e1fbc7a1-2cd1-4282-b373-ac11d9d9d38a': 3 // little mermaid
    }
}